export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
        "opportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunity"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Start Date"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "productOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Option"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage"])},
        "inspectionStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection Status"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
        "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Provider"])},
        "brokerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broker Name"])},
        "detailLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
        "lastModified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last modified"])},
        "renewalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Renewal Date"])},
        "autoRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto renewal"])},
        "labelComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
        "expiryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry Date"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Details"])},
        "activatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated date"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
        "showingEntries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Showing ", _interpolate(_list(0)), " to ", _interpolate(_list(1)), " of ", _interpolate(_list(2)), " entries"])},
        "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
        "fullPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full"])},
        "12monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 months installment"])},
        "10monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 months installment with deposit"])},
        "10monthsInstallmentNoDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 months installment"])},
        "4monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 months installment"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
        "paymentFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
        "premiumPayable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Payable"])},
        "paidToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid To Date"])},
        "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
        "cancellationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Date"])},
        "overduePremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue Premium"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Holder Name"])},
        "custMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
        "custVehicleplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Plate"])},
        "netPremiumIncludeVatAndStamp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Premium"])},
        "netPremiumIncludeVatAndStamp2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Including VAT & Stamp)"])},
        "closedWonDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated Date"])},
        "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
        "paidUntilDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid Until Date"])},
        "accumulatedPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accumulated Premium"])},
        "collectedPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collected Premium"])},
        "lastDateForInspection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last date for inspection"])},
        "cancelledDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled date"])},
        "cancellationReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Reason"])},
        "refundedPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunded Premium"])},
        "grossPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Premium"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type "])},
        "inspectionTaskCreatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection task created date"])},
        "netPremiumUnpaidOrRefund1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Premium"])},
        "netPremiumUnpaidOrRefund2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid or Refunded"])},
        "ePolicydocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Policy Document"])}
      },
      "th": {
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้างใบเสนอ"])},
        "opportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบเสนอราคา"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่กรมธรรม์เริ่มคุ้มครอง"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลิตภัณฑ์"])},
        "productOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลือกผลิตภัณฑ์"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะใบเสนอ"])},
        "inspectionStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะการตรวจสภาพรถ"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะกรมธรรม์"])},
        "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างโดย"])},
        "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ให้บริการ"])},
        "brokerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โบรกเกอร์"])},
        "detailLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียด"])},
        "lastModified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขล่าสุด"])},
        "renewalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์รอต่ออายุ"])},
        "autoRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การต่ออายุอัตโนมัติ"])},
        "labelComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มความเห็นของคุณ"])},
        "expiryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันหมดอายุกรมธรรม์"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดาวน์โหลดข้อมูล"])},
        "activatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ปิดการขาย"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การชำระเงิน"])},
        "showingEntries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["กำลังแสดง ", _interpolate(_list(0)), " ถึง ", _interpolate(_list(1)), " จาก ", _interpolate(_list(2)), " รายการ"])},
        "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้า"])},
        "fullPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จ่ายเต็มจำนวน"])},
        "12monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่อน 12 งวด"])},
        "10monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่อน 10 งวด (มีมัดจำ)"])},
        "10monthsInstallmentNoDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่อน 10 งวด"])},
        "4monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่อน 4 งวด"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีการชำระเงิน"])},
        "paymentFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การชำระเงิน"])},
        "premiumPayable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบี้ยประกันภัยที่ต้องชำระ"])},
        "paidToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอดที่ชำระมาแล้ว"])},
        "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ชำระเงิน"])},
        "cancellationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่กรมธรรม์ถูกยกเลิก"])},
        "overduePremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบี้ยประกันค้างชำระ"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเจ้าของกรมธรรม์"])},
        "custMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์โทรศัพท์"])},
        "custVehicleplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทะเบียน"])},
        "netPremiumIncludeVatAndStamp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบี้ยประกันภัยสุทธิ"])},
        "netPremiumIncludeVatAndStamp2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(รวมภาษีมูลค่าเพิ่มและตราประทับ)"])},
        "closedWonDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ปิดการขาย"])},
        "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันครบกำหนด"])},
        "paidUntilDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระเบี้ยถึงวันที่"])},
        "accumulatedPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบี้ยประกันภัยสะสม"])},
        "collectedPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอดที่เก็บได้"])},
        "lastDateForInspection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันสุดท้ายของการตรวจสภาพรถ"])},
        "cancelledDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ยกเลิกกรมธรรม์"])},
        "cancellationReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลในการยกเลิกกรมธรรม์"])},
        "refundedPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอดเงินคืน"])},
        "grossPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบี้ยประกันภัยรวม"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภท "])},
        "inspectionTaskCreatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้างงานตรวจสภาพ"])},
        "netPremiumUnpaidOrRefund1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบี้ยประกันภัย"])},
        "netPremiumUnpaidOrRefund2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ค้างชำระหรือขอคืน"])},
        "ePolicydocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์อิเล็กทรอนิกส์"])}
      },
      "zh": {
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])},
        "opportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报价号码"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保单起始日期"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品"])},
        "productOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品选项"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "inspectionStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验车状态"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持有人"])},
        "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务提供商"])},
        "brokerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经纪人姓名"])},
        "detailLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详情"])},
        "lastModified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上次修改"])},
        "renewalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待续定日期"])},
        "autoRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动续订"])},
        "labelComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
        "expiryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保单到期日"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载"])},
        "activatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激活日期"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])},
        "showingEntries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Showing ", _interpolate(_list(0)), " to ", _interpolate(_list(1)), " of ", _interpolate(_list(2)), " entries"])},
        "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
        "fullPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full"])},
        "12monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 months installment"])},
        "10monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 months installment with deposit"])},
        "10monthsInstallmentNoDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 months installment"])},
        "4monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 months installment"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付方式"])},
        "paymentFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款"])},
        "premiumPayable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应付保费"])},
        "paidToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付日期"])},
        "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付日期"])},
        "cancellationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Date"])},
        "overduePremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逾期保费"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保单持有人名"])},
        "custMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户手机号码"])},
        "custVehicleplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车牌"])},
        "netPremiumIncludeVatAndStamp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["净保费"])},
        "netPremiumIncludeVatAndStamp2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(含增值税&税票)"])},
        "closedWonDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激活日期"])},
        "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到期日"])},
        "paidUntilDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付截止日"])},
        "accumulatedPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已付保费"])},
        "collectedPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应付保费"])},
        "lastDateForInspection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后验车日期"])},
        "cancelledDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消保单日期"])},
        "cancellationReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消保单原因"])},
        "refundedPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunded Premium"])},
        "grossPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总保费"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type "])},
        "inspectionTaskCreatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验车任务创建日期"])},
        "netPremiumUnpaidOrRefund1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未付或已退还"])},
        "netPremiumUnpaidOrRefund2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["净保费"])},
        "ePolicydocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子保单"])}
      }
    }
  })
}
