<style>
#searchForm .input-group .form-control{
  border-radius: 0 0.25rem 0.25rem 0;
}

#searchForm .input-group .input-group-text {
    font-size: 0.75rem;
}

#searchForm .form-control, #searchForm .form-select{
  color: #FF5011;
  padding: 0.65rem 1.5rem;
}
</style>

<template>
  <div id="searchForm">
    <div v-if="Global.brokerDetails.accountSource !== 'Affiliate'">
      <div class="row">
        <DatePicker :pageName="reportName" :start="Global.searchConditions[reportName].dateFrom" :end="Global.searchConditions[reportName].dateTo" :dateRange="reportName === 'paymentFailureReport' ? 'thismonth' :'today'" 
            :dateType="Global.searchConditions[reportName].dateType"
            :futureDate="reportName === 'renewalReport' ? 90 : reportName === 'paymentFailureReport' ? 30 : null">
        </DatePicker>
      </div>
      <div class="row">
        <div v-bind:class="getClass()" class="col-12 mb-3">
          <div class="input-group">
            <label class="input-group-text" for="product-dropdown">{{$t("product")}}</label>
            <select
              class="form-select"
              id="product-dropdown"
              v-model="Global.searchConditions[reportName].productType"
              data-selenium-name="select-product-dropdown"
              v-bind:data-selenium-value="Global.searchConditions[reportName].productType"
            >
              <option
                v-for="(product, idx) in productList"
                v-bind:value="product.value"
                :key="product.value"
                data-selenium-name="product-dropdown-item"
                v-bind:data-selenium-value="idx+1"
              >
                {{ $t('productType.'+product.label) }}
              </option>
            </select>
          </div>
        </div>
        <div v-bind:class="getClass()" class="col-12 mb-3">
          <div class="input-group" v-if="reportName==='inspectionReport'">
            <label class="input-group-text" for="inspection-status-dropdown"
              >{{$t("inspectionStatus")}}</label
            >
            <select
              class="form-select"
              id="inspection-status-dropdown"
              v-model="Global.searchConditions[reportName].inspectionStatus"
            >
              <option
                v-for="status in inspectionStatusList"
                v-bind:value="status.value"
                :key="status.value"
              >
                {{ status.label }}
              </option>
            </select>
          </div>
          <div class="input-group" v-else-if="reportName === 'renewalReport' || reportName==='paymentFailureReport' || reportName === 'cancellationReport'">
            <label class="input-group-text" for="date-type-dropdown"
              >{{$t("dateType")}}</label
            >
            <select
              class="form-select"
              id="date-type-dropdown"
              v-model="Global.searchConditions[reportName].dateType"
              data-selenium-name="select-date-type-dropdown"
              v-bind:data-selenium-value="Global.searchConditions[reportName].dateType"
            >
              <option
                v-for="(date, idx) in typeOfDate"
                v-bind:value="date.value"
                :key="date.value"
                data-selenium-name="date-type-dropdown-item"
                v-bind:data-selenum-value="idx+1"
              >
                {{ date.label }}
              </option>
            </select>
          </div>
          <div class="input-group" v-else-if="reportName==='activepolicyReport'">
            <label class="input-group-text" for="transaction-type-dropdown"
              >{{$t("transactionType")}}</label
            >
            <select
              class="form-select"
              id="transaction-type-dropdown"
              v-model="Global.searchConditions[reportName].transactionType"
              data-selenium-name="select-transaction-dropdown"
              v-bind:data-selenium-value="Global.searchConditions[reportName].transactionType"
            >
              <option
                v-for="(data, idx) in typeOfTransaction"
                v-bind:value="data.value"
                :key="data.value"
                data-selenium-name="transaction-dropdown-item"
                v-bind:data-selenum-value="idx+1"
              >
                {{ data.label }}
              </option>
            </select>
          </div>
          <div class="input-group" v-else>
            <label class="input-group-text" for="policy-status-dropdown"
              >{{$t('stage')}}</label
            >
            <select
              class="form-select"
              id="policy-status-dropdown"
              v-model="Global.searchConditions[reportName].policyStatus"
            >
              <option
                v-for="stage in stageList"
                v-bind:value="stage.value"
                :key="stage.value"
              >
                {{ stage.label }}
              </option>
            </select>
          </div>
        </div>
        <div v-bind:class="getClass()" class="col-12 mb-3" v-if="reportName !== 'renewalReport' && reportName !== 'paymentFailureReport'  && reportName !== 'cancellationReport'">
          <div class="input-group">
            <label class="input-group-text" for="date-type-dropdown">{{$t("dateType")}}</label>
            <select
              class="form-select"
              id="date-type-dropdown"
              v-model="Global.searchConditions[reportName].dateType"
              data-selenium-name="select-date-type-dropdown"
              v-bind:data-selenium-value="Global.searchConditions[reportName].dateType"
            >
              <option
                v-for="(date, idx) in typeOfDate"
                v-bind:value="date.value"
                :key="date.value"
                data-selenium-name="date-type-dropdown-item"
                v-bind:data-selenium-value="idx+1"
              >
                {{ date.label }}
              </option>
            </select>
          </div>
        </div>
        <div v-bind:class="getClass()" class="col-12 mb-3" v-if="reportName === 'paymentFailureReport'">
          <div class="input-group">
            <label class="input-group-text" for="date-type-dropdown"
              >Payment Stage</label
            >
            <select
              class="form-select"
              id="date-type-dropdown"
              v-model="Global.searchConditions[reportName].paymentStatus"
            >
              <option
                v-for="PaymentStatus in typeOfPaymentStatus"
                v-bind:value="PaymentStatus.value"
                :key="PaymentStatus.value"
              >
                {{ $t('paymentStatus.'+PaymentStatus.label) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div :class="{'col-md-9 col-lg-9 col-xl-9 col-12' : reportName !== 'opportunityAffiliateReport'}">
          <div class="row">
            <DatePicker :pageName="reportName" :start="Global.searchConditions[reportName].dateFrom" :end="Global.searchConditions[reportName].dateTo" :dateRange="reportName === 'paymentFailureReport' ? 'lastmonth' :'today'"></DatePicker>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-xl-3 col-12 mb-3" v-if="reportName !== 'opportunityAffiliateReport'">
          <div class="input-group">
            <label class="input-group-text" for="date-type-dropdown"
              >{{$t("dateType")}}</label
            >
            <select
              class="form-select"
              id="date-type-dropdown"
              v-model="Global.searchConditions[reportName].dateType"
            >
              <option
                v-for="date in typeOfDate"
                v-bind:value="date.value"
                :key="date.value"
              >
                {{ date.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row collapse" id="adv2area">
      <div :class="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN' ? 'col-12 col-md-4 col-lg-4 col-xl-4 mb-3' : 'col-12 col-md-6 col-lg-6 col-xl-6 mb-3'">
        <div class="input-group">
          <span class="input-group-text" id="inputOpp-num"
            >{{$t("opportunity")}}</span
          >
          <input
            id="input-opp-number"
            v-model.trim="Global.searchConditions[reportName].oppNumber"
            autocomplete="off"
            type="text"
            class="form-control bg-gradient-kight"
            aria-label="date2range input"
            aria-describedby="inputOpp-num"
            data-selenium-name="opportunity"
          />
        </div>
      </div>

      <div :class="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN' ? 'col-12 col-md-4 col-lg-4 col-xl-4 mb-3' : 'col-12 col-md-6 col-lg-6 col-xl-6 mb-3'"
        v-if="['opportunityAffiliateReport', 'cancellationAffiliateReport'].indexOf(reportName) < 0">
        <div class="input-group">
          <span class="input-group-text" id="inputCus-name">{{$t("custName")}}</span>
          <input
            id="input-cust-name"
            v-model.trim="Global.searchConditions[reportName].custName"
            autocomplete="off"
            type="text"
            class="form-control"
            aria-label="date2range input"
            aria-describedby="inputCus-name"
            data-selenium-name="name"
          />
        </div>
      </div>
      <div class= "col-12 col-md-4 col-lg-4 col-xl-4 mb-3"
        v-if="['opportunityAffiliateReport', 'cancellationAffiliateReport'].indexOf(reportName) < 0 && (Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN')">
        <div class="input-group">
          <span class="input-group-text" id="inputAgent-email">{{$t("provider")}}</span>
          <input
            id="input-agent-email"
            v-model.trim="Global.searchConditions[reportName].agentEmail"
            autocomplete="off"
            type="text"
            class="form-control"
            aria-label="date2range input"
            aria-describedby="inputAgent-email"
          />
        </div>
      </div>

      <div class="col-12 col-md-4 col-lg-4 col-xl-4 mb-3" v-if="['opportunityAffiliateReport', 'cancellationAffiliateReport'].indexOf(reportName) < 0">
        <div class="input-group">
          <span class="input-group-text" id="inputPhone-num">{{$t("phoneNumber")}}</span>
          <input
            id="input-phone-number"
            v-model.trim="Global.searchConditions[reportName].phoneNumber"
            autocomplete="off"
            type="tel"
            class="form-control"
            aria-label="date2range input"
            aria-describedby="inputPhone-num"
            data-selenium-name="phone-number"
          />
        </div>
      </div>

      <div class="col-12 col-md-4 col-lg-4 col-xl-4 mb-3" v-if="['opportunityAffiliateReport', 'cancellationAffiliateReport'].indexOf(reportName) < 0">
        <div class="input-group">
          <span class="input-group-text" id="inputCus-mail">{{$t("custEmail")}}</span>
          <input
            id="input-cust-email"
            v-model.trim="Global.searchConditions[reportName].custEmail"
            autocomplete="off"
            type="text"
            class="form-control"
            aria-label="date2range input"
            aria-describedby="inputCus-mail"
            data-selenium-name="email"
          />
        </div>
      </div>

      <div class="col-12 col-md-4 col-lg-4 col-xl-4 mb-3" v-if="['opportunityAffiliateReport', 'cancellationAffiliateReport'].indexOf(reportName) < 0">
        <div class="input-group">
          <span class="input-group-text" id="inputCar-palte">{{$t("carPlate")}}</span>
          <input
            id="input-car-plate"
            v-model.trim="Global.searchConditions[reportName].carPlate"
            autocomplete="off"
            type="text"
            class="form-control"
            aria-label="date2range input"
            aria-describedby="inputCar-palte"
            data-selenium-name="plate"
          />
        </div>
      </div>

      <div class="col-12 mb-0"></div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-8 col-xl-9 mb-3">
        <button
          id="show2adv"
          class="btn btn-sm bg-white-line text-xs py-1 px-3 text-white collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#adv2area"
          aria-expanded="false"
          aria-controls="adv2area"
          data-selenium-name="advance-search"
        >
          {{$t("advanceSearch")}}
          <i class="fa fa-arrow-up text-sm ms-1" aria-hidden="true"></i>
        </button>
        <button
          id="hide2adv"
          class="btn btn-sm bg-white-line text-xs py-1 px-3 text-white d-none"
          onclick="hide2adv();"
        >
          Hide advance search
          <i class="fa fa-arrow-up text-sm ms-1" aria-hidden="true"></i>
        </button>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4 mt-1">
        <button
          type="submit"
          class="btn bg-gradient-primary btn-block text-white"
          :disabled="isProcessing"
          @click="Global.searchConditions[reportName].currentPage = 1; $emit('search')"
          data-selenium-name="search-refresh"
        >
          <i class="fas fa-search text-sm ms-1" aria-hidden="true"></i> {{$t('submit')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Global from "@/stores/GlobalVariables.js";
import DatePicker from "@/components/DatePicker";
export default {
  name: "SearchForm",
  components: {
    DatePicker,
  },
  data: function () {
    return {
      Global,
      productList: [
        { label: "all", value: "" },
        { label: "car", value: "MotorCar" },
        { label: "carEv", value: "MotorCarEV" },
        { label: "motorBike", value: "MotorBike" },
        { label: "health", value: "Health" },
        { label: "personalAccident", value: "Personal Accident" },
        { label: "extendedWarranty", value: "Extended Warranty"},
        { label: "criticalIllness", value: "CriticalIllness"},
        { label: "PAComboSave", value: "PAComboSave"},
      ],
      stageList: [
        { label: "All", value: "" },
        { label: "Qualification", value: "Qualification" },
        { label: "Proposal", value: "Proposal" },
        { label: "Closed Won", value: "Closed Won" },
        { label: "Closed Lost", value: "Closed Lost" },
      ],
      statusList: [
        { label: "All", value: "" },
        { label: "Activated", value: "Activated" },
        { label: "Cancelled", value: "Cancelled" },
        { label: "Draft", value: "Draft" }
      ],
      inspectionStatusList: [
        { label: "All", value: "" },
        { label: "Open", value: "Open" },
        { label: "Approved", value: "Closed" },
        { label: "Pending", value: "In Progress" },
        { label: "Rejected", value: "Rejected" },
      ],
      typeOfDate: [
        { label: "Created date", value: "createddate" },
        { label: "Transacted date", value: "transactedate" },
        { label: "Last modified date", value: "lastmodifieddate" },
        { label: "Activated date", value: "activatedate" },
        { label: "Cancelled date", value: "cancelleddate"}
      ],
      typeOfPaymentStatus: [
        { label: "All", value: "" },
        { label: "Over Due", value: "overdue" },
      ],
      typeOfTransaction:[
        { label: "All", value: "" },
        { label: "New Business", value: "newbiz" },
        { label: "Existing Business", value: "renew" },
      ],
      searchConditions: null,
    };
  },
  props: {
    reportName: String,
    isProcessing: Boolean
  },
  emits: ["search"],
  created() {
    if(this.reportName === "renewalReport"){
      this.stageList = this.stageList.filter(policyStatus => policyStatus.value !== "Qualification");
      this.typeOfDate = [{label:'Pending renewal date', value:'renewaldate'}, {label:'Expiry date', value:'expirydate'}];
      this.productList = this.productList.filter(product => ["CriticalIllness", "Extended Warranty" ].indexOf(product.value) < 0);
    }else if(this.reportName === "inspectionReport"){
      this.productList = this.productList.filter(product => ["", "MotorCar", "MotorCarEV", "MotorBike", "Extended Warranty" ].indexOf(product.value) > -1);
      this.typeOfDate = this.typeOfDate.filter(product => [""].indexOf(product.value) > -1);
      this.typeOfDate.push({ label: "Inspection task created date", value: "inspectiontaskcreateddate" });
      this.typeOfDate.push({ label: "Last date for inspection", value: "lastdateforinspection" });
    }else if(this.reportName === "opportunityAffiliateReport"){
      this.typeOfDate = this.typeOfDate.filter(date => ["createddate", "activatedate"].indexOf(date.value) > -1)
    }else if(this.reportName === "cancellationAffiliateReport"){
      this.typeOfDate = this.typeOfDate.filter(date => ["createddate", "cancelleddate"].indexOf(date.value) > -1)
    }else if(this.reportName === "paymentFailureReport"){
      this.typeOfDate.push({ label: "Due date", value: "duedate" });
      this.typeOfDate.push({ label: "Payment date", value: "paymentdate" });
      this.typeOfDate = this.typeOfDate.filter(date => ["duedate", "paymentdate"].indexOf(date.value) > -1);
    }else if(this.reportName === "opportunityReport"){
      this.typeOfDate = this.typeOfDate.filter(date => ["createddate", "lastmodifieddate"].indexOf(date.value) > -1);
      this.stageList = this.stageList.filter(date => ["Proposal"].indexOf(date.value) > -1);
    }else if(this.reportName === "activepolicyReport"){
      this.typeOfDate.push({ label: "Policy start date ", value: "startdate" });
      this.typeOfDate = this.typeOfDate.filter(date => ["startdate", "activatedate","transactedate"].indexOf(date.value) > -1);
      this.stageList = this.stageList.filter(date => ["Closed Won"].indexOf(date.value) > -1);
    }else if(this.reportName === "cancellationReport"){      
      this.typeOfDate = this.typeOfDate.filter(date => ["activatedate", "cancelleddate"].indexOf(date.value) > -1);
      this.typeOfDate.unshift({ label: "Policy start date ", value: "start_date" });      
      this.typeOfDate.unshift({ label: "Closed won date ", value: "closed_won_date" });
    }
  },
  methods: {
    getClass() {
      return {
        'col-md-6 col-lg-6 col-xl-6' : this.reportName === 'renewalReport' ||  this.reportName === 'cancellationReport',
        'col-md-3 col-lg-3 col-xl-4' : this.reportName === 'opportunityReport' || this.reportName === 'paymentFailureReport' ,
        'col-md-3 col-lg-3 col-xl-3' : this.reportName === 'activepolicyReport',
        'col-md-4 col-lg-4 col-xl-4' : !['renewalReport'].includes(this.reportName)  
      }
   }
  },
  mounted(){
    var vm = this;
    vm.$watch(
      () => (
        Global.searchConditions[vm.reportName].dateFrom, 
        Global.searchConditions[vm.reportName].dateTo, 
        Global.searchConditions[vm.reportName].dateType, 
        Global.searchConditions[vm.reportName].stage,
        Global.searchConditions[vm.reportName].policyStatus, 
        Global.searchConditions[vm.reportName].productType,
        Global.searchConditions[vm.reportName].oppNumber,
        Global.searchConditions[vm.reportName].phoneNumber, 
        Global.searchConditions[vm.reportName].custName, 
        Global.searchConditions[vm.reportName].custEmail,
        Global.searchConditions[vm.reportName].carPlate,
        Date.now()
      ),
      function () {
        let val = Global.searchConditions[vm.reportName].oppNumber + Global.searchConditions[vm.reportName].phoneNumber + Global.searchConditions[vm.reportName].custName + Global.searchConditions[vm.reportName].custEmail + Global.searchConditions[vm.reportName].carPlate
        if(val){
          Global.searchConditions[vm.reportName].isAdvanceSearch = true;
        }else{
          Global.searchConditions[vm.reportName].isAdvanceSearch = false;
        }
      }
  )
  },
  computed: {},
  watch: {},
};
</script>

<i18n>
{
  "en": {
    "product": "Product",
    "dateType": "Date type",
    "stage": "Stage",
    "policyStatus": "Policy Status",
    "advanceSearch": "Advance search",
    "opportunity": "Opportunity",
    "phoneNumber": "Phone Number",
    "custName": "Customer Name",
    "custEmail": "Customer Email",
    "carPlate": "Car Plate",
    "provider": "Service Provider",
    "submit": "SEARCH / REFRESH",
    "inspectionStatus": "Inspection Status",
    "productType": {            
      "all":"All",
      "car": "MotorCar",
      "carEv": "MotorCarEV",
      "extendedWarranty": "Extended Warranty",
      "motorBike" : "MotorBike", 
      "personalAccident": "Personal Accident",
      "health": "Cancer",
      "criticalIllness": "Critical Illness",
      "PAComboSave": "PA Combo Save"
    },
    "paymentStatus":{
      'All': 'All',
      'Over Due': 'Over Due'
    },
    "dateSelectType":{
      "createddate": "Created Date",
      "activatedate": "Activate Date",
      "duedate": "Due Date",
      "paymentdate": "Payment Date",
      "startdate": "Policy Start Date",
      "cancelleddate": "Cancelled Date",
      "closed_won_date": "Closed Won Date",
      "lastmodifieddate": "Last Modified Date",
      "inspectiontaskcreateddate": "Inspection Task Created Date",
      "lastdateforinspection": "Last Date For Inspection"
    },
    "transactionType":"Transaction type"
  },
  "th": {
    "product": "ผลิตภัณฑ์",
    "dateType": "กรองข้อมูล",
    "stage": "สถานะใบเสนอ",
    "policyStatus": "สถานะกรมธรรม์",
    "advanceSearch": "ค้นหาเพิ่มเติม",
    "opportunity": "กรมธรรม์",
    "phoneNumber": "เบอร์โทรศัพท์",
    "custName": "ชื่อลูกค้า",
    "custEmail": "อีเมล์",
    "carPlate": "ป้ายทะเบียน",
    "provider": "ผู้ให้บริการ",
    "submit": "ค้นหา / อัพเดตข้อมูล",
    "inspectionStatus": "สถานะการตรวจสภาพรถ",
    "productType": {
      "all":"ทั้งหมด", 
      "car": "ประกันรถยนต์",
      "carEv": "ประกันรถยนต์ไฟฟ้า",
      "extendedWarranty": "ประกันอะไหล่รถยนต์",
      "motorBike" : "ประกันมอเตอร์ไซค์", 
      "personalAccident": "ประกันอุบัติเหตุส่วนบุคคล",
      "health": "ประกันมะเร็ง",
      "criticalIllness": "ประกันโรคร้ายแรง",
      "PAComboSave": "PA Combo Save"
    },
    "paymentStatus":{
      'All': 'ทั้งหมด',
      'Over Due': 'เกินกำหนดชำระ'
    },
    "dateSelectType":{
      "createddate": "วันที่สร้าง",
      "activatedate": "วันที่เริ่มใช้",
      "duedate": "วันที่ครบกำหนด",
      "paymentdate": "วันที่ชำระเงิน",
      "startdate": "วันที่เริ่มกรมธรรม์",
      "cancelleddate": "วันที่ยกเลิก",
      "closed_won_date": "วันที่ปิดการขาย",
      "lastmodifieddate": "วันที่แก้ไขล่าสุด",
      "inspectiontaskcreateddate": "วันที่สร้างงานตรวจสภาพรถ",
      "lastdateforinspection": "วันที่ตรวจสภาพรถล่าสุด"
    },
    "transactionType":"ประเภทรายการ"
  },
  "zh": {
    "product": "产品",
    "dateType": "日期类别",
    "stage": "Stage",
    "policyStatus": "保单状态",
    "advanceSearch": "进阶搜索",
    "opportunity": "机会",
    "phoneNumber": "客户手机号码",
    "custName": "名称",
    "custEmail": "客户邮箱",
    "carPlate": "车牌",
    "provider": "服务提供商",
    "submit": "查询 / 刷新",
    "inspectionStatus": "Inspection Status",
    "productType": {            
      "all":"All product",
      "car": "汽车保险",
      "carEv": "MotorCarEV",
      "extendedWarranty": "延长车辆质保保险",
      "motorBike" : "摩托车保险", 
      "personalAccident": "个人意外保险",
      "health": "癌症保险",
      "criticalIllness": "Critical illness",
      "PAComboSave": "PA Combo Save"
    },
    "paymentStatus":{
      'All': 'All',
      'Over Due': 'Over Due'
    },
    "dateSelectType":{
      "createddate": "Created Date",
      "activatedate": "Activate Date",
      "duedate": "Due Date",
      "paymentdate": "Payment Date",
      "startdate": "Policy Start Date",
      "cancelleddate": "Cancelled Date",
      "closed_won_date": "Closed Won Date",
      "lastmodifieddate": "Last Modified Date",
      "inspectiontaskcreateddate": "Inspection Task Created Date",
      "lastdateforinspection": "Last Date For Inspection"
    },
    "transactionType":"交易类别"
  }
}
</i18n>