export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "dateType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date type"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage"])},
        "policyStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Status"])},
        "advanceSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advance search"])},
        "opportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunity"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])},
        "custEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Email"])},
        "carPlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Plate"])},
        "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Provider"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH / REFRESH"])},
        "inspectionStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection Status"])},
        "productType": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
          "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MotorCar"])},
          "carEv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MotorCarEV"])},
          "extendedWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extended Warranty"])},
          "motorBike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MotorBike"])},
          "personalAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Accident"])},
          "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancer"])},
          "criticalIllness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical Illness"])},
          "PAComboSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PA Combo Save"])}
        },
        "paymentStatus": {
          "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
          "Over Due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over Due"])}
        },
        "dateSelectType": {
          "createddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
          "activatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Date"])},
          "duedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
          "paymentdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
          "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Start Date"])},
          "cancelleddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled Date"])},
          "closed_won_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed Won Date"])},
          "lastmodifieddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Modified Date"])},
          "inspectiontaskcreateddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection Task Created Date"])},
          "lastdateforinspection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Date For Inspection"])}
        },
        "transactionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction type"])}
      },
      "th": {
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลิตภัณฑ์"])},
        "dateType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรองข้อมูล"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะใบเสนอ"])},
        "policyStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะกรมธรรม์"])},
        "advanceSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาเพิ่มเติม"])},
        "opportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์โทรศัพท์"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อลูกค้า"])},
        "custEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล์"])},
        "carPlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้ายทะเบียน"])},
        "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ให้บริการ"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา / อัพเดตข้อมูล"])},
        "inspectionStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะการตรวจสภาพรถ"])},
        "productType": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
          "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันรถยนต์"])},
          "carEv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันรถยนต์ไฟฟ้า"])},
          "extendedWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันอะไหล่รถยนต์"])},
          "motorBike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันมอเตอร์ไซค์"])},
          "personalAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันอุบัติเหตุส่วนบุคคล"])},
          "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันมะเร็ง"])},
          "criticalIllness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันโรคร้ายแรง"])},
          "PAComboSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PA Combo Save"])}
        },
        "paymentStatus": {
          "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
          "Over Due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกินกำหนดชำระ"])}
        },
        "dateSelectType": {
          "createddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้าง"])},
          "activatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่เริ่มใช้"])},
          "duedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ครบกำหนด"])},
          "paymentdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ชำระเงิน"])},
          "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่เริ่มกรมธรรม์"])},
          "cancelleddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ยกเลิก"])},
          "closed_won_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ปิดการขาย"])},
          "lastmodifieddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่แก้ไขล่าสุด"])},
          "inspectiontaskcreateddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้างงานตรวจสภาพรถ"])},
          "lastdateforinspection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ตรวจสภาพรถล่าสุด"])}
        },
        "transactionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทรายการ"])}
      },
      "zh": {
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品"])},
        "dateType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期类别"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage"])},
        "policyStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保单状态"])},
        "advanceSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进阶搜索"])},
        "opportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机会"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户手机号码"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "custEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户邮箱"])},
        "carPlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车牌"])},
        "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务提供商"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查询 / 刷新"])},
        "inspectionStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection Status"])},
        "productType": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All product"])},
          "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汽车保险"])},
          "carEv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MotorCarEV"])},
          "extendedWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["延长车辆质保保险"])},
          "motorBike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摩托车保险"])},
          "personalAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人意外保险"])},
          "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["癌症保险"])},
          "criticalIllness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical illness"])},
          "PAComboSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PA Combo Save"])}
        },
        "paymentStatus": {
          "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
          "Over Due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over Due"])}
        },
        "dateSelectType": {
          "createddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
          "activatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Date"])},
          "duedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
          "paymentdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
          "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Start Date"])},
          "cancelleddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled Date"])},
          "closed_won_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed Won Date"])},
          "lastmodifieddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Modified Date"])},
          "inspectiontaskcreateddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection Task Created Date"])},
          "lastdateforinspection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Date For Inspection"])}
        },
        "transactionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易类别"])}
      }
    }
  })
}
